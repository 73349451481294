<template>
    <v-dialog
        @input="$event || $store.commit('errorHandling/clear')"
        :value="messages.length > 0"
        width="500px"
    >
        <v-card>
            <v-toolbar flat dark color="error" dense>
                {{ __('An error occurred') }}
            </v-toolbar>
            <v-card-text :key="message" v-for="message in messages">
                {{ message }}
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="error"
                    @click="$store.commit('errorHandling/clear')"
                >
                    {{ __('Clear errors and close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    computed: {
        messages() {
            return this.$store.state.errorHandling.messages.filter(
                (v, i, a) => a.indexOf(v) === i
            );
        },
    },
};
</script>
