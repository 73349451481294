import sleep from './sleep.js';

// fetch itself only rejects on network errors, so this will not retry
// HTTP errors
export default async function retryingFetch(resource, options) {
    const delay = 0.2;
    let tries = 3;
    let t = 0;
    while (1) {
        t++;
        try {
            return await fetch(resource, options);
        } catch (e) {
            if (t >= tries) {
                throw e;
            }
        }
        console.warn(
            `Fetch failed with probable network error, retrying in ${delay} s... (${t})`
        );
        await sleep(delay);
    }
}
