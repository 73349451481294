import router from './router';
import VueRouter from 'vue-router';

export class SessionExpiredError extends Error {
    constructor(message) {
        super(message);
        this.name = 'SessionExpiredError';
    }
}

export async function unauthorizedRedirect(employee) {
    if (!isRedirectToLoginPage(router)) {
        router
            .push({
                path: employee ? 'employeeLogin' : 'userLogin',
                query: { path: router.currentRoute.path },
            })
            .catch(err => {
                if (
                    [
                        VueRouter.NavigationFailureType.duplicated,
                        VueRouter.NavigationFailureType.cancelled,
                    ].includes(t => VueRouter.isNavigationFailure(err, t))
                ) {
                    return;
                }
                throw err;
            });
    }
}
function isRedirectToLoginPage(router) {
    return ['userLogin', 'employeeLogin'].includes(router.currentRoute.name);
}
