const map = new WeakMap();

let lastIndex = 0;

export default function keyFor(o, salt = '') {
    if (!map.has(o)) {
        map.set(o, `keyFor${++lastIndex}`);
    }
    return `${map.get(o)}${salt ? `-${salt}` : ''}`;
}
