<template>
    <div>
        <v-snackbar
            v-for="snackbar in snackbars"
            :key="keyFor(snackbar)"
            :value="true"
            @input="removeSnackbar(snackbar)"
        >
            {{ snackbar.snack }}
        </v-snackbar>
    </div>
</template>
<script>
export default {
    methods: {
        removeSnackbar(snackbar) {
            this.$store.commit('snackbar/removeSnackbar', snackbar);
        },
    },
    computed: {
        snackbars() {
            return this.$store.state.snackbar.snackbars;
        },
    },
};
</script>
