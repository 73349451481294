export default function (spec) {
    Object.entries(spec).forEach(([k, v]) => {
        if (typeof v !== 'function') {
            throw new Error(
                `makeFieldTranscoder spec key ${k} must be function, not ${typeof v}`
            );
        }
    });

    return o =>
        o !== null
            ? Object.fromEntries(
                  Object.entries(o).map(([k, v]) => [
                      k,
                      spec[k] ? spec[k](v) : v,
                  ])
              )
            : null;
}
