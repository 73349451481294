import padNumberLeft from './padNumberLeft.js';

export default function minutesToHours(minutes) {
    /* Takes a number and returns
    a string in the form HH:mm, or -HH:mm if negative */
    if (minutes == null) {
        return minutes;
    }
    const sign = minutes < 0 ? '-' : '';
    return [
        sign + padNumberLeft(Math.floor(Math.abs(minutes) / 60), 2),
        padNumberLeft(Math.round(Math.abs(minutes % 60)), 2),
    ].join(':');
}
