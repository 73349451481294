import _moment from 'moment';
import makeFieldTranscoder from './helpers/makeFieldTranscoder';

const employeeAvailabilityRequest = makeFieldTranscoder({
    start: moment,
    end: moment,
    deadline: moment,
});

const employeeAvailabilityRequests = mapper(employeeAvailabilityRequest);

const availabilityRequest = makeFieldTranscoder({
    start_date: moment,
    end_date: moment,
    created_at: moment,
    deadline: moment,
});

const availabilityRequests = mapper(availabilityRequest);

const totalCostAndHoursPerStation = mapper(
    makeFieldTranscoder({
        duration: momentDuration,
    })
);
const totalCostAndHoursPerEmployee = totalCostAndHoursPerStation;

const totalPersonnelHours = makeFieldTranscoder({
    total_personnel_hours: momentDuration,
});

const getEmployeeCostFactors = mapper(
    makeFieldTranscoder({
        valid_from: moment,
    })
);
const attendances = mapper(
    makeFieldTranscoder({
        start: moment,
        end: moment,
        start_corrected: moment,
        end_corrected: moment,
        break_duration: momentDuration,
    })
);

const schedule = makeFieldTranscoder({
    created_at: moment,
    awaiting_approval_at: moment,
    rejected_at: moment,
    published_at: moment,
});

const scheduleCost = mapper(
    makeFieldTranscoder({
        date: moment,
    })
);

const shifts = mapper(
    makeFieldTranscoder({
        start: moment,
        end: moment,
        break_duration: momentDuration,
    })
);

const wageTax = mapper(
    makeFieldTranscoder({
        valid_from: moment,
    })
);

const contract = makeFieldTranscoder({
    valid_from: moment,
    valid_to: moment,
});

const employeeInfo = makeFieldTranscoder({
    date_of_birth: moment,
});

const availability = makeFieldTranscoder({ start: moment, end: moment });

export default {
    availabilityRequests,
    availabilities: mapper(availability),
    firms: mapper(firm => {
        const utcoffset = momentDuration(firm.utcoffset);
        return {
            ...firm,
            expected_revenue_deadline: momentDuration(
                firm.expected_revenue_deadline
            ),
            now: () =>
                _moment()
                    .add(new Date().getTimezoneOffset(), 'minutes')
                    .add(utcoffset),
        };
    }),
    scheduleCost,
    employeeInfo,
    getEmployeeCostFactors,
    schedule,
    employeeAvailabilityRequests,
    shifts,
    totalCostAndHoursPerStation,
    totalCostAndHoursPerEmployee,
    attendances,
    contracts: mapper(contract),
    conflicts,
    totalPersonnelHours,
    contractMeta: makeFieldTranscoder({
        contract_start_date: moment,
        contract_end_date: moment,
        employee_salary: parseFloat,
        employee_contract_hours: parseInt,
    }),
    warnings,
    wageTax,
    certificate_coverage,
};

function certificate_coverage(l) {
    return l.map(([dt, b]) => [moment(dt), b]);
}

function warnings(w) {
    return mapObjectValues(w, rules =>
        mapObjectValues(rules, makeFieldTranscoder({ shifts }))
    );
}

function conflicts(c) {
    return mapObjectValues(c, makeFieldTranscoder({ shifts: mapper(shifts) }));
}

function mapObjectValues(o, fn) {
    return Object.fromEntries(Object.entries(o).map(([k, v]) => [k, fn(v)]));
}

function momentDuration(v) {
    if (v === null || v === undefined) {
        return v;
    }
    const d = _moment.duration(v);
    if (!d.isValid()) {
        console.error('Got invalid duration from backend:', v);
        return null;
    }
    return d;
}

function moment(v) {
    if (v === null || v === undefined) {
        return v;
    }
    const m = _moment(v);
    if (!m.isValid()) {
        console.error('Got invalid datetime from backend:', v);
        return null;
    }
    return m;
}

function mapper(f) {
    if (typeof f !== 'function') {
        throw new Error('mapper needs a function');
    }
    return v => v.map(f);
}
