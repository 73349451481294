
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"cashFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CashType"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"total"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"note500Count"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"note200Count"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"note100Count"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"note050Count"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"note020Count"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"note010Count"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"note005Count"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"coin200Count"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"coin100Count"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"coin050Count"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"coin020Count"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"coin010Count"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"coin005Count"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"coin002Count"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"coin001Count"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":301}};
    doc.loc.source = {"body":"fragment cashFields on CashType {\n    total\n    note500Count\n    note200Count\n    note100Count\n    note050Count\n    note020Count\n    note010Count\n    note005Count\n    coin200Count\n    coin100Count\n    coin050Count\n    coin020Count\n    coin010Count\n    coin005Count\n    coin002Count\n    coin001Count\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

    // Collect any fragment/type references from a node, adding them to the refs Set
    function collectFragmentReferences(node, refs) {
      if (node.kind === "FragmentSpread") {
        refs.add(node.name.value);
      } else if (node.kind === "VariableDefinition") {
        var type = node.type;
        if (type.kind === "NamedType") {
          refs.add(type.name.value);
        }
      }

      if (node.selectionSet) {
        node.selectionSet.selections.forEach(function(selection) {
          collectFragmentReferences(selection, refs);
        });
      }

      if (node.variableDefinitions) {
        node.variableDefinitions.forEach(function(def) {
          collectFragmentReferences(def, refs);
        });
      }

      if (node.definitions) {
        node.definitions.forEach(function(def) {
          collectFragmentReferences(def, refs);
        });
      }
    }

    var definitionRefs = {};
    (function extractReferences() {
      doc.definitions.forEach(function(def) {
        if (def.name) {
          var refs = new Set();
          collectFragmentReferences(def, refs);
          definitionRefs[def.name.value] = refs;
        }
      });
    })();

    function findOperation(doc, name) {
      for (var i = 0; i < doc.definitions.length; i++) {
        var element = doc.definitions[i];
        if (element.name && element.name.value == name) {
          return element;
        }
      }
    }

    function oneQuery(doc, operationName) {
      // Copy the DocumentNode, but clear out the definitions
      var newDoc = {
        kind: doc.kind,
        definitions: [findOperation(doc, operationName)]
      };
      if (doc.hasOwnProperty("loc")) {
        newDoc.loc = doc.loc;
      }

      // Now, for the operation we're running, find any fragments referenced by
      // it or the fragments it references
      var opRefs = definitionRefs[operationName] || new Set();
      var allRefs = new Set();
      var newRefs = new Set();

      // IE 11 doesn't support "new Set(iterable)", so we add the members of opRefs to newRefs one by one
      opRefs.forEach(function(refName) {
        newRefs.add(refName);
      });

      while (newRefs.size > 0) {
        var prevRefs = newRefs;
        newRefs = new Set();

        prevRefs.forEach(function(refName) {
          if (!allRefs.has(refName)) {
            allRefs.add(refName);
            var childRefs = definitionRefs[refName] || new Set();
            childRefs.forEach(function(childRef) {
              newRefs.add(childRef);
            });
          }
        });
      }

      allRefs.forEach(function(refName) {
        var op = findOperation(doc, refName);
        if (op) {
          newDoc.definitions.push(op);
        }
      });

      return newDoc;
    }
    
    module.exports = doc;
    
        module.exports["cashFields"] = oneQuery(doc, "cashFields");
        
