import { ref, computed, watch } from 'vue';
import nlMessages from 'json-loader!po-loader!../../i18n/nl.po';
import { formatMessage } from './i18n-parser.js';

export const language = ref('en');

export const locale = computed(() => {
    if (language.value === 'nl') return 'nl-NL';
    return 'en-US';
});

export const localeCompare = computed(
    () => (a, b) => a.localeCompare(b, locale.value)
);

const messages = computed(() => (language.value === 'nl' ? nlMessages : {}));

const getMessage = m => {
    if (m === '') return m;
    const t = messages.value[m];
    if (t === undefined || t[1] === '') return m;
    return t[1];
};

export function __(m, ...args) {
    m = getMessage(m);
    if (args.length === 0) return m;
    return formatMessage(
        m,
        ...args.map(arg => {
            if (typeof arg === 'number') {
                return numberFormat.value.format(arg);
            }
            return arg;
        })
    );
}

export const I18nPlugin = vue => {
    vue.mixin({
        methods: {
            __,
        },
    });
};

export const numberFormat = computed(() => new Intl.NumberFormat(locale.value));

if (/^nl/.test(navigator.language)) {
    language.value = 'nl';
}

try {
    const lang = localStorage.getItem('lang');
    if (['en', 'nl'].includes(lang)) {
        language.value = lang;
    }
} catch (e) {}

watch(
    () => language.value,
    v => void localStorage.setItem('lang', v)
);
