import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const light = {
    primary: '#005377',
    secondary: '#af4010',
    accent: '#2f45ac',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
};

export default new Vuetify({
    theme: { themes: { light } },
});
