import { computed } from 'vue';
import PluralRules from './PluralRules.js';
import { locale } from '../i18n.js';

const rules = computed(
    () => new PluralRules(locale.value, { type: 'ordinal' })
);

const suffixes = {
    'en-US': {
        one: 'st',
        two: 'nd',
        few: 'rd',
        other: 'th',
    },
    'nl-NL': {
        other: 'e',
    },
};

export default function (v) {
    const category = rules.value.select(Number(v));
    const suffix = (suffixes[locale.value] ?? {})[category] ?? '�';
    return `${v}${suffix}`;
}
