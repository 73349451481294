const reporters = [];

export function registerErrorReporter(reporter) {
    reporters.push(reporter);
}

export function handleError(error) {
    for (const reporter of reporters) {
        let message;
        try {
            message = reporter(error);
        } catch (e) {
            console.error('Reporter failed:', e);
            continue;
        }
        if (message == null) {
            continue;
        }
        console.error(
            `Adding global error to store with message "${message}":`,
            error
        );
        import('./store/store').then(({ store }) => {
            store.commit('errorHandling/addMessage', message);
        });
        return true;
    }
    return false;
}

window.addEventListener('unhandledrejection', e => {
    handleError(e.reason);
});
