import { __ } from '../i18n';

export default function employeeName(employee) {
    if (!employee) return __('No employee');
    if (employee.name) {
        return `${employee.name} ${employee.prefix} ${employee.surname}`
            .replace(/\s+/g, ' ')
            .trim();
    }
    return employee.email;
}
