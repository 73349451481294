export default {
    namespaced: true,
    state: () => ({
        messages: [],
    }),
    mutations: {
        clear(state) {
            state.messages = [];
        },
        addMessage(state, message) {
            state.messages = [...state.messages, message];
        },
    },
};
