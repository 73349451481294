import getPluralRules from 'intl-pluralrules/factory';
import { en, nl } from 'make-plural/plurals';
import { en as enCat, nl as nlCat } from 'make-plural/pluralCategories';
import { en as enRange, nl as nlRange } from 'make-plural/ranges';

const sel = { en, nl };
const getSelector = lc => sel[lc];

const cat = { en: enCat, nl: nlCat };
const getCategories = (lc, ord) => cat[lc][ord ? 'ordinal' : 'cardinal'];

const range = { en: enRange, nl: nlRange };
const getRangeSelector = lc => range[lc];

const PluralRules = getPluralRules(
    Intl.NumberFormat, // Not available in IE 10
    getSelector,
    getCategories,
    getRangeSelector
);

export default PluralRules;
