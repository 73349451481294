export default {
    namespaced: true,
    state: () => ({
        isKioskMode: false,
    }),
    mutations: {
        setMode(state, mode) {
            state.isKioskMode = mode;
        },
    },
};
