export default {
    namespaced: true,
    state: () => ({
        snackbars: [],
    }),
    mutations: {
        clear(state) {
            state.snackbars = [];
        },
        addSnackbar(state, snackbar) {
            state.snackbars = [...state.snackbars, snackbar];
        },
        removeSnackbar(state, snackbar) {
            state.snackbars = state.snackbars.filter(s => s !== snackbar);
        },
    },
};
