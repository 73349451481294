import { locale } from '../i18n';

export default function (v) {
    if (isNaN(v)) return;
    if (typeof v !== 'number') {
        return v;
    }
    return new Intl.NumberFormat(locale.value, {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(v);
}
