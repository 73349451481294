import { computed } from 'vue';
import { locale } from '../i18n.js';

const formatter = computed(
    () =>
        new Intl.NumberFormat(locale.value, {
            style: 'currency',
            currency: 'EUR',
        })
);

export default function (v) {
    if (typeof v !== 'number') {
        return v;
    }
    return formatter.value.format(v);
}
