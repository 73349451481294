import { VBtn } from 'vuetify/lib';

let queue = Promise.resolve();

export default {
    extends: VBtn,
    methods: {
        click(...args) {
            if (
                this.$el &&
                this.$el.closest &&
                this.$el.closest('.v-speed-dial__list')
            ) {
                VBtn.options.methods.click.call(this, ...args);
                return;
            }

            let wasDestroyed = false;

            const onDestroy = () => void (wasDestroyed = true);
            this.$on('hook:beforeDestroy', onDestroy);

            queue = queue
                .then(() => new Promise(resolve => void setTimeout(resolve)))
                .then(() => {
                    if (wasDestroyed)
                        throw new Error(
                            'Button component was destroyed before click could be handled'
                        );
                    if (this.disabled)
                        throw new Error(
                            'Button component was disabled before click could be handled'
                        );
                    VBtn.options.methods.click.call(this, ...args);
                })
                .catch(e => void console.error(e))
                .then(() => void this.$off('hook:beforeDestroy', onDestroy));
        },
    },
};
