window.dataLayer = window.dataLayer || [];

const tagId = 'G-YXBRQ67HR3';

export function startAnalytics() {
    const url = new URL('https://www.googletagmanager.com/gtag/js');
    url.searchParams.set('id', tagId);

    const script = document.createElement('script');
    script.src = url.toString();

    const firstScript = document.querySelector('script');
    firstScript.parentNode.insertBefore(script, firstScript);

    gtag('js', new Date());
    gtag('config', tagId, {
        page_title: '',
        page_location: location.origin,
        page_referrer: '',
        send_page_view: false,
        allow_google_signals: false,
        allow_ad_personalization_signals: false,
    });
}

export function gtag() {
    window.dataLayer.push(arguments);
}

export function addRouterGuards(router) {
    router.afterEach((to, from) => {
        gtag('event', 'page_view', {
            page_title: routePath(to).replace(/^\/*/, ''),
            page_location: routeUrl(to),
            page_referrer: routeUrl(from),
        });
    });
}

function routeUrl(route) {
    const path = routePath(route);
    return path && location.origin + path;
}

function routePath(route) {
    try {
        return route.matched[route.matched.length - 1].path;
    } catch (e) {
        return '';
    }
}
