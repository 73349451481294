const pattern = /([\s\S]*?)(?:(%%)|%([1-9][0-9]*)|(%\[)|]([1-9][0-9]*)|$)/y;

export function parseMessage(msg) {
    pattern.lastIndex = 0;

    const stack = [new ParametrizedSlot()];
    const tip = () => stack[stack.length - 1];

    const pushText = t => {
        const tok = tip().peek();
        if (tok instanceof Text) {
            tok.value += t;
        } else {
            tip().push(new Text(t));
        }
    };

    while (pattern.lastIndex < msg.length) {
        const m = pattern.exec(msg);
        if (!m) {
            throw new Error('Pattern should always match');
        }

        if (m[1].length > 0) {
            pushText(m[1]);
        }

        if (m[2] !== undefined) {
            pushText('%');
        }

        if (m[3] !== undefined) {
            tip().push(new Placeholder(parseInt(m[3], 10)));
        }

        if (m[4] !== undefined) {
            const v = new ParametrizedSlot();
            tip().push(v);
            stack.push(v);
        }

        if (m[5] !== undefined) {
            if (stack.length === 1) {
                console.error('Unexpected "]" in message:', msg);
            } else {
                tip().idx = parseInt(m[5], 10);
                stack.pop();
            }
        }
    }

    if (stack.length !== 1) {
        console.error('Unterminated parametrized slot in message:', msg);
    }

    return stack[0].tokens;
}

export class Text {
    constructor(value) {
        this.value = value;
    }
}

export class Placeholder {
    constructor(idx) {
        this.idx = idx;
    }
}

export class ParametrizedSlot {
    constructor(idx, tokens = []) {
        this.idx = idx;
        this.tokens = tokens;
    }

    push(token) {
        this.tokens.push(token);
    }

    peek() {
        return this.tokens[this.tokens.length - 1];
    }
}

export function formatMessage(msg, ...args) {
    const render = tokens => {
        let result = '';

        for (const token of tokens) {
            if (token instanceof Text) {
                result += token.value;
            } else if (token instanceof Placeholder) {
                result += args[token.idx - 1];
            } else if (token instanceof ParametrizedSlot) {
                result += render(token.tokens);
            }
        }

        return result;
    };

    return render(parseMessage(msg));
}
