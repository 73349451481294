export default {
    namespaced: true,
    state: () => ({
        value: null,
    }),
    mutations: {
        update(state, version) {
            state.value = version;
        },
    },
};
