import { VInput, VSelect } from 'vuetify/lib';

const buildMixin = getEventName => ({
    inject: {
        trackInput: {
            from: 'trackInput',
            default: () => () => {},
        },
    },
    created() {
        const eventName = getEventName(this);
        this.$on(eventName, (...args) => {
            this.trackInput(this, eventName, ...args);
        });
    },
});

export function installTrackInputMixins() {
    VInput.mixin(buildMixin(vm => vm.$_modelEvent));
    VSelect.mixin(buildMixin(() => 'change'));
}

export const trackInput = buildMixin(() => 'input');
