import moment from 'moment';
import formatDate from './formatDate.js';

export default function (p) {
    if (!p) return '';
    return formatDate(
        moment()
            .month(p.period - 1) // zero indexed by moment
            .year(p.year),
        { month: 'long', year: 'numeric' }
    );
}
