import { locale } from '../i18n.js';

export default function (
    date,
    options = { day: 'numeric', month: 'long', year: 'numeric' }
) {
    if (date == null || !date.isValid()) {
        return null;
    }
    return new Intl.DateTimeFormat(locale.value, {
        hour12: false,
        ...options,
    }).format(date.toDate());
}
