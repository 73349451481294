import { computed } from 'vue';
import { locale } from '../i18n.js';

const formatter = computed(
    () =>
        new Intl.NumberFormat(locale.value, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
);

export default function formatHours(v) {
    return formatter.value.format(v);
}
