import formatDate from './formatDate';
import formatDateRange from './formatDateRange';
import formatMoney from './formatMoney';
import formatPercentage from './formatPercentage';
import formatDuration from './formatDuration';
import employeeName from './employeeName';
import minutesToHours from './minutesToHours';
import keyFor from './keyFor';
import formatHours from './formatHours';
import formatPeriod from './formatPeriod';
import formatHoursMinutes from './formatHoursMinutes';
import formatOrdinal from './formatOrdinal';
import formatProducedHoursSource from './formatProducedHoursSource';

export default {
    methods: {
        $emitChange(values) {
            this.$emit('input', { ...this.value, ...values });
        },
        $emitChangeArray(id, values) {
            this.$emit(
                'input',
                this.value.map(a => (a.id === id ? { ...a, ...values } : a))
            );
        },
        $formatDate: formatDate,
        $formatDateRange: formatDateRange,
        $formatMoney: formatMoney,
        $formatPercentage: formatPercentage,
        $formatHours: formatHours,
        $formatPeriod: formatPeriod,
        $employeeName: employeeName,
        $minutesToHours: minutesToHours,
        $formatHoursMinutes: formatHoursMinutes,
        $formatDuration: formatDuration,
        $formatOrdinal: formatOrdinal,
        $formatProducedHoursSource: formatProducedHoursSource,
        keyFor,
    },
};
