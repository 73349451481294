import makeFieldTranscoder from './helpers/makeFieldTranscoder';

const formatDate = makeFormatter('YYYY-MM-DD');
const formatDateTime = makeFormatter('YYYY-MM-DD HH:mm');

async function nmbrsData(d) {
    return {
        ...d,
        whk_beschikking: await fileToDataUri(d.whk_beschikking),
        wage_declarations: await fileToDataUri(d.wage_declarations),
        payslips: await fileToDataUri(d.payslips),
    };
}

async function employee(e) {
    return {
        ...e,
        dateOfBirth: formatDate(e.dateOfBirth),
        passport: e.passport ? await passport(e.passport) : null,
    };
}

async function passport(p) {
    return {
        ...p,
        validTo: formatDate(p.validTo),
        document: await fileToDataUri(p.document),
        documentBackside: await fileToDataUri(p.documentBackside),
    };
}

async function signedContract(c) {
    return {
        ...c,
        signed_contract: await fileToDataUri(c.signed_contract),
    };
}

async function contractTemplate(n) {
    return {
        ...n,
        template_file: await fileToDataUri(n.template_file),
    };
}

async function endOfDay(e) {
    return {
        ...e,
        date: formatDate(e.date),
        attendances: e.attendances.map(attendance),
        payment_method_counts: await Promise.all(
            e.payment_method_counts.map(paymentMethodCount)
        ),
    };
}

function saveShiftCopies(p) {
    return { ...p, copy_dates: p.copy_dates.map(d => formatDate(d)) };
}

async function createCertificate(c) {
    return {
        ...c,
        valid_from: formatDate(c.valid_from),
        valid_to: formatDate(c.valid_to),
        certificate_file: await fileToDataUri(c.certificate_file),
    };
}

async function createHours(p) {
    return {
        ...p,
        hours: {
            ...p.hours,
            valid_from: formatDate(p.hours.valid_from),
            proof: await fileToDataUri(p.hours.proof),
        },
    };
}

async function paymentMethodCount(count) {
    return {
        ...count,
        proof: await fileToDataUri(count.proof),
    };
}
async function wageTax(w) {
    return {
        ...w,
        valid_from: formatDate(w.valid_from),
        proof: await fileToDataUri(w.proof),
    };
}

export function fileToDataUri(file) {
    if (!file) return null;
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(reader.error);
        reader.readAsDataURL(file);
    });
}

function newPrePlannedShift(v) {
    return {
        ...v,
        pre_planned_shift: prePlannedShift(v.pre_planned_shift),
    };
}

const createCostFactor = makeFieldTranscoder({
    valid_from: formatDate,
});

const prePlannedShift = makeFieldTranscoder({
    start: formatDateTime,
    end: formatDateTime,
    breakDuration: formatDuration,
});

const updatePrePlannedShift = makeFieldTranscoder({
    new_shift: prePlannedShift,
});

const publish = makeFieldTranscoder({
    max_date: formatDate,
});

const attendance = makeFieldTranscoder({
    start: formatDateTime,
    end: formatDateTime,
    start_corrected: formatDateTime,
    end_corrected: formatDateTime,
});

const availability = makeFieldTranscoder({
    start: formatDateTime,
    end: formatDateTime,
});

function availabilities(v) {
    return { availabilities: v.availabilities.map(availability) };
}

async function saveEmployeeOnboarding(v) {
    return {
        ...v,
        employee: {
            ...v.employee,
            dateOfBirth: formatDate(v.employee.dateOfBirth),
        },
    };
}

function formatDuration(v) {
    return v.toISOString();
}

const shift = makeFieldTranscoder({
    start: formatDateTime,
    end: formatDateTime,
    break_duration: formatDuration,
});

const deleteShifts = makeFieldTranscoder({
    start: formatDate,
});
const copyShifts = makeFieldTranscoder({
    start: formatDate,
    copy_to_date: formatDate,
});

const createPrePlannedScheduleOfDayWeek = makeFieldTranscoder({
    start: formatDate,
});

const updateShift = makeFieldTranscoder({
    new_shift: shift,
});

export default {
    attendance,
    availabilities,
    contractTemplate,
    copyShifts,
    createCertificate,
    createCostFactor,
    createHours,
    createPrePlannedScheduleOfDayWeek,
    createWorkRelation: makeFieldTranscoder({ start_date: formatDate }),
    deleteShifts,
    employee,
    endOfDay,
    newPrePlannedShift,
    nmbrsData,
    prePlannedShift,
    publish,
    saveEmployeeOnboarding,
    saveShiftCopies,
    shift,
    signedContract,
    updatePrePlannedShift,
    updateShift,
    wageTax,
};

function makeFormatter(format) {
    return v => (v ? v.format(format) : null);
}
