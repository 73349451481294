export default {
    install(Vue, options) {
        Vue.directive('observe-size', directive);
    },
};

const observerKey = Symbol('ResizeObserver for observe-size');

const directive = {
    bind(el, { value }) {
        const observer = new ResizeObserver(entries => {
            for (const entry of entries) {
                value(entry);
            }
        });
        el[observerKey] = observer;
        observer.observe(el);
    },
    unbind(el) {
        if (el[observerKey]) {
            el[observerKey].disconnect();
            delete el[observerKey];
        }
    },
};
